import React from "react";
import Image from "../modules/Image.js";
import EventShortDes from "../modules/EventShortDes.js";

import default_img_src from "../../img/logo/univ_192x192.png";
import { catToColor } from "../../data/colorKeys.js"

import "./AllEvents.css"
import "../../utilities.css"

const currentTime = new Date()

const AllEvents = ({eventsArr, natureFltr, catFltr}) => (
  <section className="u-gridPic-3">
    {eventsArr.filter(
      (item) => {
        if (item.attributes.endDate) {
          // if ending time is defined, display events before ending time
          return new Date(item.attributes.endDate) > currentTime
        } else {
          // otherwise, display events before (starting time + 30mins)
          return new Date(item.attributes.startDate) > new Date(currentTime.getTime() - 30*60000);
        }
      }).filter(
        // filter by nature
        (item) => {
        // if no filter turns on then return all true
        if (!Object.values(natureFltr).some(item => item)) {
          return true
        } else {
          return item.attributes.natures.data.some((oneNature) => natureFltr[oneNature.attributes.name])
        }
      }).filter(
        // filter by category
        (item) => {
        // if no filter turns on then return all true
        if (!Object.values(catFltr).some(item => item)) {
          return true
        } else {
          return catFltr[item.attributes.category]
        }
      }).map(
        (item) => {
        return (
            <Image 
            key={item.id}
            title="" 
            src={item.attributes.banner.data ? item.attributes.banner.data.attributes.url : default_img_src}
            opacity={item.attributes.banner.data ? 1 : .5}
            isBlack={Boolean(true)}
            aspect="16/9"
            suppressArrow={Boolean(true)}
            des={<EventShortDes attributes={item.attributes}/>}
            desBkg={`rgba(${catToColor[item.attributes.category].lightBkgColor},0.3)`}
            linkdes={`/events/${item.id}`}
            isLocal={Boolean(true)}
            altText={item.attributes.banner.data ? item.attributes.banner.data.attributes.alternativeText : ""}/>
        )}
    )}
  </section>
)

export default AllEvents