import React from "react";

import "../../utilities.css";
import "./SocietyBasicInfo.css";

import { tagToColor } from "../../data/colorKeys.js";

/**
 * Profile Left is a component in profile displaying personal detail
 * of a user.
 *
 * Proptypes
 * @param societyObj for user object
 */

const SocietyBasicInfo = ( {societyObj} ) => {
  let finalKey = societyObj.nature || "Other"

  return (
    <div className="societybasicinfo-basic">
      <p>Also known as <br/> <b>{societyObj.shortName}</b></p>
      <p>Nature 
        <br/> 
        <span className="societybasicinfo-nature" 
              style={{
              backgroundColor: `rgb(${tagToColor[finalKey].bkgColor})`,
              color: `rgb(${tagToColor[finalKey].textColor}`,
              }}
              > 
          {societyObj.nature}
        </span>
      </p>

      {societyObj.president.data && 
        <p>
          President
          <br/>
          {societyObj.president.data.attributes.Website 
            ? 
              <a href={`${societyObj.president.data.attributes.Website}`}><b>{societyObj.president.data.attributes.Name}</b></a>
            : 
            <>{societyObj.president.data.attributes.Email 
              ? 
              <a href={`mailto:${societyObj.president.data.attributes.Email}`}><b>{societyObj.president.data.attributes.Name}</b></a>
              :
              <b>{societyObj.president.data.attributes.Name}</b>
            }</>
          }
        </p>
      }

      {societyObj.wcr_contacts.data.length !== 0 && 
        <p>WCR Contacts <br/>
          {societyObj.wcr_contacts.data.map((item) => {
            if (item.attributes.Website) {
              return (
                <b key={item.key} className="societybasicinfo-wcrcontact">
                  <a href={item.attributes.Website}>{item.attributes.Name}</a>
                </b>)
            } else if (item.attributes.Email) {
              return (
                <b key={item.key} className="societybasicinfo-wcrcontact">
                  <a href={`mailto:${item.attributes.Email}`}>{item.attributes.Name}</a>
                </b>)
            } else
            return (
              <b key={item.key} className="societybasicinfo-wcrcontact">
                {item.attributes.Name}
              </b>)
          })}
        </p>
      }

      <p>
        {societyObj.email && (<><a href={`mailto:${societyObj.email}`}><i className="fa-solid fa-envelope fa-3x"></i></a> </>)}
        {societyObj.websiteLink && (<><a href={societyObj.websiteLink}><i className="fa-solid fa-globe fa-3x"></i></a> </>)}
        {societyObj.facebookLink && (<><a href={societyObj.facebookLink}><i className="fa-brands fa-facebook fa-3x"></i></a> </>)}
        {societyObj.instagramLink && (<><a href={societyObj.instagramLink}><i className="fa-brands fa-instagram fa-3x"></i></a> </>)}
      </p>
    </div>
  );
};

export default SocietyBasicInfo;