import React from "react";

import "../../utilities.css"
import "./About.css";

import TopBanner from "../modules/TopBanner.js";
import ContentBlock from "../modules/ContentBlock.js";
import ButtonFlex from "../modules/ButtonFlex.js";

import TopBannerImage from "../../img/topbanner/temp_mcr_room.jpg";

const About = () => {
  return (
    <>
      <TopBanner backgroundImage={TopBannerImage} title="About" content="About" />

      <ContentBlock title="The graduate community of University College, Oxford.">
        <p>This Common Room was founded in the early 1960s to represent Univ graduate members, and was named after Mr. JG Weir, a foundation fellow of Univ. We strive:</p>
          <ul>
            <li>to promote the interests and welfare of WCR members; </li>
            <li>to organise social activities among WCR members; and</li>
            <li>to represent the interests of WCR members to the Governing Body of the College.</li>
          </ul>
      </ContentBlock>

      <ContentBlock title="Our Building">
        <p>
          Our newly-refurbished WCR room is situated on the first floor of the Mitchell building, right behind the Old library. It is a regular 
          meeting-point for the graduate community here at Univ. A whole suite of events are held in the WCR from drinks 
          receptions, art socials, discussion groups and our graduate bops. The building is John Fryman of the Architects Design 
          Partnership, and is named after Sir Harold Mitchell, an Old Member of the College, who paid for its construction.
        </p>
      </ContentBlock>
      <ContentBlock>
        <ButtonFlex display={[
          {key: 1, isLocal: false, des: "Click here to learn more about the history of the WCR!", link: "https://www.univ.ox.ac.uk/news/a-history-of-the-mitchell-building/"}
        ]}/>
      </ContentBlock>
      <ContentBlock title="Facilities">
        <ul>
          <li>Common Room Bar, available for use during events,</li>
          <li>fully equipped kitchen with a coffee machine,</li>
          <li>comfortable lounge with plenty of seatings,</li>
          <li>pigeon holes (or “pidges”) that hosts all mail sent to you during your stay in on-site college accommodation,</li>
          <li>two gender-neutral bathrooms (one cubicle only, one cubicle and urinal),</li>
          <li>a printer,</li>
          <li>a television equipped with gaming consoles, and</li>
          <li>an advanced sound system.</li>
        </ul>
      </ContentBlock>
    </>
  );
}

export default About;
