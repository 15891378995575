import React, { useState, useEffect }from "react";
import axios from "axios";
// import { Fade } from "react-awesome-reveal";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import AllSocieties from "../../modules/AllSocieties.js";
import FilterMenu from "../../modules/FilterMenu.js";
import ButtonFlex from "../../modules/ButtonFlex.js";

import "../../../utilities.css"
import "./SocietyAZ.css";

import { tagToColor } from "../../../data/colorKeys.js"
import TopBannerImage from "../../../img/topbanner/univ_women_boat_club.jpg";

const defaultNatureFilters = {
  "Academic Related": false,
  "Arts": false,
  "Social": false,
  "Sports": false,
}

const SocietyAZ = () => {
  const [societies, setSocieties] = useState([]);

  const fetchComm = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api";
    const resSocieties = await axios.get(
      `${BaseURL}/societies?populate=banner&pagination[pageSize]=100`).then().catch(e => {console.log(e)});
    setSocieties(resSocieties.data.data);
  };

  useEffect(() => {fetchComm();}, []);

  const [natureFltr, setNatureFltr] = useState(defaultNatureFilters);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f] = !newNatureFltr[f]
    setNatureFltr(newNatureFltr)
  }

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (<>
    <TopBanner 
      backgroundImage={TopBannerImage}
      bgColorKey="black"
      title="What's On" 
      content="Societies" 
    />

    {/* <Fade cascade={true} direction="up" triggerOnce> */}
    <ContentBlock title="Societies">
      <p>At Univ, there are many student college societies run for members of the college to 
        fulfil many different potential interests. The societies are sometimes run soley 
        by WCR members, or jointly with JCR. If you have an interest in joining 
        any of these societies, you can get in touch with the listed contact for the 
        society of interest and find out how you can get involved. You can also join other 
        university societies as well.</p>
        <ButtonFlex display={[
          {key: 1, isLocal: true, des: "Create a college society!", link: "/societies-supports"},
          {key: 2, isLocal: false, des: "Room booking info", link: "https://intranet.univ.ox.ac.uk/sites/default/files/Pink%20Slip%20required%20information%2023-24%20-%2016%20Oct%2023.pdf"},
          {key: 3, isLocal: false, des: "Check room capacities", link: "https://intranet.univ.ox.ac.uk/sites/default/files/Maximum%20Room%20Capacities%20-%20Oct%202023.pdf"}
        ]}/>
    </ContentBlock>

    <ContentBlock title="Societies A to Z">
      <p>Please browse below the list of college societies available for postgraduates. 
        The list is incomplete, and does not include undergraduate societies. See above for 
        registering your societies in our listings.</p>
      <section>
        <h3 style={{marginBottom: ".5rem"}}>Filter by the nature of societies:</h3>
        <FilterMenu 
          filter={natureFltr}
          updateFilter={updateNatureFilter}
          colorKeys={tagToColor}
        />
      </section>
      <section>
        <label htmlFor="societyaz-search"><h3 className="u-nomargin">Search fo the society below:</h3></label>
        <input 
          type="text"
          id="societyaz-search"
          value={search}
          onChange={handleSearchChange}
        />
      </section>
      <AllSocieties 
        societiesArr={societies}
        natureFltr={natureFltr}
        search={search}
      />
    </ContentBlock>
    {/* </Fade> */}
    </>
  );
}

export default SocietyAZ;