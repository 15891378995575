const tagToColor = {
  "Academic Related": {bkgColor: "229,52,44", textColor: "255,255,255"},
  "Arts": {bkgColor: "255,87,51", textColor: "255,255,255"},
  "Chaplaincy": {bkgColor: "26,111,43", textColor: "255,255,255"},
  "College Development": {bkgColor: "251,189,26", textColor: "0,59,121"},
  "Freshers": {bkgColor: "255,255,255", textColor: "0,0,0"},
  "Music": {bkgColor: "255,255,255", textColor: "255,255,255"},
  "Other": {bkgColor: "69,69,69", textColor: "255,255,255"},
  "Social": { bkgColor: "148,18,118", textColor: "255,255,255"},
  "Sports": { bkgColor: "0,59,121", textColor: "255,255,255"},
  "Welfare": { bkgColor: "83,151,40", textColor: "255,255,255"},
  "Equality and Access": { bkgColor: "83,151,40", textColor: "255,255,255"},
  "LGBTQ+": { bkgColor: "246,169,184", textColor: "0,59,121"},
};

const catToColor = {
  "College Official": {bkgColor: "251,189,26", lightBkgColor: "251,189,26", textColor: "0,59,121"},
  "Common Room": {bkgColor: "0,59,121", lightBkgColor: "91,206,250", textColor: "255,255,255"},
  "Student Society": {bkgColor: "26,111,43", lightBkgColor: "26,111,43", textColor: "255,255,255"},
  "Other Initiatives": {bkgColor: "69,69,69", lightBkgColor: "170,170,170", textColor: "255,255,255"},
  "Other": {bkgColor: "69,69,69", lightBkgColor: "170,170,170", textColor: "255,255,255"},
}

const statusToColor = {
  "To be confirmed": {bkgColor: "0,0,0", textColor: "255,255,255"},
  "Happening Now": {bkgColor: "26,111,43", textColor: "255,255,255"},
  "Confirmed": {bkgColor: "0,59,121", textColor: "255,255,255"},
  "Cancelled": {bkgColor: "255,0,0", textColor: "255,255,255"},
  "Postponed": {bkgColor: "255,0,0", textColor: "255,255,255"},
};

export {tagToColor, catToColor, statusToColor}