import React, {useState, useEffect} from "react";
// import { Fade } from "react-awesome-reveal";

import axios from "axios";
import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";
import FilterMenu from "../../modules/FilterMenu.js";
import AllEvents from "../../modules/AllEvents.js";

import { tagToColor, catToColor } from "../../../data/colorKeys.js"

import food_in_wcr from "../../../img/topbanner/food_in_wcr.png";

import "../../../utilities.css"
import "./WhatsOn.css";

const defaultNatureFilters = {
  "Academic Related": false,
  "Arts": false,
  "Chaplaincy": false,
  "College Development": false,
  "Equality and Access": false,
  "LGBTQ+": false,
  "Social": false,
  "Sports": false,
  "Welfare": false,
  "Other": false,
  "Freshers": false,
}

const defaultCatFilters = {
  "College Official": false,
  "Common Room": false,
  "Student Society": false,
  "Other Initiatives": false,
}

const WhatsOn = () => {
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api"
    const response = await axios.get(`${BaseURL}/events?sort=startDate&pagination[pageSize]=200&populate=banner&populate=mainContact&populate=natures`);
    setEvents(response.data.data);
  };

  useEffect(() => {getEvents();}, []);

  const [natureFltr, setNatureFltr] = useState(defaultNatureFilters);
  const [catFltr, setCatFltr] = useState(defaultCatFilters);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f] = !newNatureFltr[f]
    setNatureFltr(newNatureFltr)
  }

  const updateCatFilter = (f) => {
    let newCatFltr = { ...catFltr }
    newCatFltr[f] = !newCatFltr[f]
    setCatFltr(newCatFltr)
  }

  return (
    <>
      <TopBanner
        backgroundImage={food_in_wcr}
        bgColorKey="black"
        title="What's On" 
        content="List of Events" />
        
      <ContentBlock title="Events">
        <p>Please search below upcoming events happening in the college.</p>
        <section className="u-gridPic-2">
          <div>
            <h3>Filter by the nature of events:</h3>
            <FilterMenu 
              filter={natureFltr}
              updateFilter={updateNatureFilter}
              colorKeys={tagToColor}
            />
          </div>
          <div>
            <h3>Filter by organiser:</h3>
            <FilterMenu 
              filter={catFltr}
              updateFilter={updateCatFilter}
              colorKeys={catToColor}
            />
          </div>
        </section>

        <AllEvents 
          eventsArr={events}
          natureFltr={natureFltr}
          catFltr={catFltr}
        />
      </ContentBlock>
    </>
  );
};
  
export default WhatsOn;
