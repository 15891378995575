import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import { Fade } from "react-awesome-reveal";

import "../../utilities.css"
import "./Home.css";

import TopBanner from "../modules/TopBanner.js";
import EventShortDes from "../modules/EventShortDes.js";
import ButtonFlex from "../modules/ButtonFlex.js";
import Image from "../modules/Image.js";
import ContentBlock from "../modules/ContentBlock.js";

import { catToColor } from "../../data/colorKeys.js";
import TopBannerImage from "../../img/topbanner/temp_eurovision.png";

// const axios = require("axios");

const Home = () => {
  const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api";

  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  const getNews = async () => {
    const response = await axios.get(`${BaseURL}/news?sort=updatedAt:desc&pagination[pageSize]=3&populate=banner`);
    setNews(response.data.data);
  };

  const getEvents = async () => {
    const response = await axios.get(`${BaseURL}/events?sort=startDate&pagination[pageSize]=100&populate=banner&populate=mainContact&populate=natures`);
    setEvents(response.data.data);
  };

  useEffect(() => {getNews(); getEvents();}, []);

  return (
    <>
      <TopBanner 
        backgroundImage={TopBannerImage} 
        bgColorKey="black" 
        title="Home" 
        content="Welcome to the University College WCR" />
      <ContentBlock title="Freshers' Updates">
        <div>
          We are glad that you are joining us this year! Click below to view the latest information for your arrival. 
          Please also check out our <Link to="/freshers">dedicated freshers' page</Link>.
        </div>
        <ButtonFlex display={[
          {key: 1, isLocal: true, des: "WCR Freshers' Page", link: "/freshers"},
          {key: 2, isLocal: false, des: "College New Student Page", link: "https://www.univ.ox.ac.uk/learn-at-univ/new-students"}
        ]}/>
        <div className="u-gridPic-3">
          {news.map(
              (item) => {
                return (
                  <Image 
                    key={item.id}
                    title="" 
                    src={item.attributes.banner.data ? item.attributes.banner.data.attributes.url : ""}
                    opacity={item.attributes.banner.data ? 1 : .5}
                    isBlack={Boolean(true)}
                    aspect="16/9"
                    suppressArrow={Boolean(true)}
                    des={<>
                      <h4 className="u-nomargin">{item.attributes.title}</h4>
                      <p>{item.attributes.content.slice(0, 85)}...</p>
                    </>}
                    linkdes={`/welcome/${item.attributes.shortTitle}`}
                    isLocal={Boolean(true)}
                    altText={item.attributes.banner.data ? item.attributes.banner.data.attributes.alternativeText : ""}/>
                )}
            )}
        </div>
      </ContentBlock>
      <ContentBlock title="Upcoming Events">
        <div>For more events, please visit our <Link to="/whatson">What's on</Link> page.</div>
        <div className="u-gridPic-3">
          {events.filter(
              (item) => {
                const currentTime = new Date()

                if (item.attributes.endDate) {
                  return new Date(item.attributes.endDate) > currentTime
                } else {
                  return new Date(item.attributes.startDate) > new Date(currentTime.getTime() - 30*60000);
                }
              }
            ).slice(0,3).map(
              (item) => {
                return (
                  <Image 
                    key={item.id}
                    title="" 
                    src={item.attributes.banner.data ? item.attributes.banner.data.attributes.url : ""}
                    opacity={item.attributes.banner.data ? 1 : .5}
                    isBlack={Boolean(true)}
                    aspect="16/9"
                    suppressArrow={Boolean(true)}
                    des={<EventShortDes 
                      attributes={item.attributes}
                      />}
                    desBkg={`rgba(${catToColor[item.attributes.category].lightBkgColor},0.3)`}
                    linkdes={`/events/${item.id}`}
                    isLocal={Boolean(true)}
                    altText={item.attributes.banner.data ? item.attributes.banner.data.attributes.alternativeText : ""}/>
                )}
            )}
        </div>
      </ContentBlock>
      {/* <ContentBlock title="WCR Termcard">
        <div className="home-termcard-container">
          <img src={TermCard} style={{width: "100%"}} alt="Please refer to the what's on page for an accessible version of the list of events."/>
        </div>
      </ContentBlock> */}
    </>
  );
}

export default Home;
