const commOrder = {
    "President": 1,
    "Acting President": 2,
    "Vice President / Treasurer": 3,
    "Vice President / Secretary": 4,
    "Vice President": 5,
    "Treasurer": 6,
    "Secretary": 7,
}

export default commOrder