import React from "react";
import EventBasicInfo from "./EventBasicInfo.js";

import "../../utilities.css";
import "./EventBanner.css";

import { catToColor } from "../../data/colorKeys.js";

/**
 * EventBanner is a component in profile displaying details of a WCR event.
 *
 * Proptypes
 * @param eventObj for a single event object.
 */

const EventBanner = ( {eventObj} ) => {
  let finalCategory = eventObj.category || "Other"
  const actualBkgColor = catToColor[finalCategory].bkgColor;
  const actualColor = catToColor[finalCategory].textColor;
  
  let bgStyle = {};
  
  try {
    bgStyle = {backgroundImage: `linear-gradient(rgba(${actualBkgColor},0.8), 
      rgba(${actualBkgColor},0.8)), 
      url(${eventObj.banner.data.attributes.url})`};
  } catch {
    bgStyle = {
      backgroundColor: `rgb(${actualBkgColor})`,
    };
  }
  
  return (
    <>
      <div className="eventbanner" style={bgStyle}>
        <div className="eventbanner-bodycontainer">
          <div className="eventbanner-left">
            <h3 className="eventbanner-title" style={{color: `rgb(${actualColor})`}}>Event</h3>
            <h1 className="eventbanner-content" style={{color: `rgb(${actualColor})`}}> 
              <span className="eventbanner-line" style={{color: `rgb(${actualColor})`}}></span>
              {eventObj.title} 
            </h1>
          </div>
          <div className="eventbanner-right">
          </div>
        </div>
      </div>
      <div className="eventbanner-basicinfo">
        <EventBasicInfo eventObj={eventObj}/>
      </div>
    </>
  );
}

export default EventBanner;