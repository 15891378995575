import React from "react";
import EventStatus from "./EventStatus.js";

import "../../utilities.css";
import "./EventBasicInfo.css";

import { tagToColor } from "../../data/colorKeys.js";

/**
 * EventBasicInfo is a component in EventBanner displaying 
 * basic information of a WCR event on a event page.
 *
 * Proptypes
 * @param eventObj for user object
 */

const EventBasicInfo = ( {eventObj} ) => {
  return (
    <div className="eventbasicinfo-basic">
      <EventStatus 
        startDate={eventObj.startDate}
        endDate={eventObj.endDate}
        status={eventObj.status}
      />
      <p>
        A {eventObj.category} event, happening <br/>
        <b>{new Date(eventObj.startDate).toLocaleDateString(
                undefined, {
                  weekday: 'short', 
                  year: 'numeric', 
                  month: 'short', 
                  day: 'numeric', 
                  hour: '2-digit', 
                  hour12: false,
                  minute: '2-digit',
                })}
        </b>
      </p>
      <p>
        Venue <br/>
        { eventObj.secondVenue ? (
            <>
              <b>{eventObj.firstVenue}</b>, then <br/> <b>{eventObj.secondVenue}</b>
            </>
          ) : (
            <b>{eventObj.firstVenue}</b>
          )
        }
      </p>
      <p>
        Nature of event<br/>
        {eventObj.natures && eventObj.natures.data.map((tag) => {
          return (
            <span key={tag.id} className="eventbasicinfo-nature" 
                  style={{backgroundColor: `rgb(${tagToColor[tag.attributes.name].bkgColor})`,
                          color: `rgb(${tagToColor[tag.attributes.name].textColor})`,
                        }}
            >
              {tag.attributes.name}
            </span>);
          }
        )}
      </p>

      {eventObj.promotionLink && <a 
        style={{textDecoration: "none"}}
        href={eventObj.promotionLink}><button className="eventbasicinfo-register">Information / Register Here</button></a>}
                
      {eventObj.eventLink && <a 
        style={{textDecoration: "none"}}
        href={eventObj.eventLink}><button className="eventbasicinfo-eventlink">Join here!</button></a>}
    </div>
  );
};

export default EventBasicInfo;