import React, {useState, useEffect} from "react";
// import { Fade } from "react-awesome-reveal";

import axios from "axios";
import ContentBlock from "../../modules/ContentBlock.js";
import ButtonFlex from "../../modules/ButtonFlex.js";
import Image from "../../modules/Image.js";
import TopBanner from "../../modules/TopBanner.js";
import FilterMenu from "../../modules/FilterMenu.js";
import AllEvents from "../../modules/AllEvents.js";

import { tagToColor, catToColor } from "../../../data/colorKeys.js"

import TopBannerImage from "../../../img/topbanner/temp_univ_goose.jpg";
import default_img_src from "../../../img/logo/univ_192x192.png";

import "../../../utilities.css"
import "./Freshers.css";

const defaultNatureFilters = {
  "Academic Related": false,
  "Arts": false,
  "Chaplaincy": false,
  "College Development": false,
  "Social": false,
  "Sports": false,
  "Welfare": false,
  "Equality and Access": false,
  "LGBTQ+": false,
  "Other": false,
  "Freshers": true,
}

const defaultCatFilters = {
  "College Official": true,
  "Common Room": true,
  "Student Society": false,
  "Other Initiatives": false,
}


const Freshers = () => {
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  const getNews = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api"
    const response = await axios.get(`${BaseURL}/news?sort=updatedAt:desc&pagination[pageSize]=25&populate=banner`);
    setNews(response.data.data);
  };

  useEffect(() => {getNews();}, []);

  const getEvents = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api"
    const response = await axios.get(`${BaseURL}/events?sort=startDate&pagination[pageSize]=200&populate=banner&populate=mainContact&populate=natures`);
    setEvents(response.data.data);
  };

  useEffect(() => {getEvents();}, []);

  const [natureFltr, setNatureFltr] = useState(defaultNatureFilters);
  const [catFltr, setCatFltr] = useState(defaultCatFilters);

  const updateNatureFilter = (f) => {
    let newNatureFltr = { ...natureFltr }
    newNatureFltr[f] = !newNatureFltr[f]
    setNatureFltr(newNatureFltr)
  }

  const updateCatFilter = (f) => {
    let newCatFltr = { ...catFltr }
    newCatFltr[f] = !newCatFltr[f]
    setCatFltr(newCatFltr)
  }

  return (
    <>
      <TopBanner
        backgroundImage={TopBannerImage}
        bgColorKey="black"
        title="Freshers" 
        content="Freshers' Information" />
      <ContentBlock title="Hey there!">
        <p>You should have received official welcomes from various College members, and we look forward to your arrival as much as they do! 
          Please do ensure that you have completed the registrations on the College's <a href="https://www.univ.ox.ac.uk/learn-at-univ/new-students">New Students</a> page.
          You can find on this page the WCR welcoming letters, as well as other freshers' week information.</p>
        <ButtonFlex display={[
          {key: 1, isLocal: false, des: "Official New Student Page", link: "https://www.univ.ox.ac.uk/learn-at-univ/new-students"},
        ]}/>
        <div className="u-gridPic-3">
          {news.map(
              (item) => {
                return (
                  <Image 
                    key={item.id}
                    title="" 
                    src={item.attributes.banner.data ? item.attributes.banner.data.attributes.url : default_img_src}
                    opacity={item.attributes.banner.data ? 1 : .5}
                    isBlack={Boolean(true)}
                    aspect="16/9"
                    suppressArrow={Boolean(true)}
                    des={<>
                      <h4 className="u-nomargin">{item.attributes.title}</h4>
                      <p>{item.attributes.content.slice(0, 85)}...</p>
                    </>}
                    linkdes={`/welcome/${item.attributes.shortTitle}`}
                    isLocal={Boolean(true)}
                    altText={item.attributes.banner.data ? item.attributes.banner.data.attributes.alternativeText : ""}/>
                )}
            )}
        </div>
      </ContentBlock>
      <ContentBlock title="Freshers' Events">
        <p>Please search below upcoming events happening in the college.</p>
        <section className="u-gridPic-2">
          <div>
            <h3>Filter by the nature of events:</h3>
            <FilterMenu 
              filter={natureFltr}
              updateFilter={updateNatureFilter}
              colorKeys={tagToColor}
            />
          </div>
          <div>
            <h3>Filter by organiser:</h3>
            <FilterMenu 
              filter={catFltr}
              updateFilter={updateCatFilter}
              colorKeys={catToColor}
            />
          </div>
        </section>
        <AllEvents 
          eventsArr={events}
          natureFltr={natureFltr}
          catFltr={catFltr}
        />
      </ContentBlock>
    </>
  );
};
  
export default Freshers;
