import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
// import { Fade } from "react-awesome-reveal";

import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";

import "../../../utilities.css"
import "./FreshersUpdate.css";


const FreshersUpdate = () => {
  let { updateShortTitle } = useParams();

  const [event, setEvent] = useState({});

  const getEvent = async () => {
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api"
    const response = await axios.get(`${BaseURL}/news?filters[shortTitle][$eq]=${updateShortTitle}&populate[author][populate][0]=profilePicture&populate=banner`);
    setEvent(response.data.data[0].attributes);
  };

  useEffect(() => {getEvent();}, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    event.title ? (
    <>
      <TopBanner
        backgroundImage={event.banner.data.attributes.url}
        bgColorKey="black"
        title="Freshers > Updates"
        content={event.title} />
      <ContentBlock title="Author">
        {event.author.data.map(author => (
          <Link 
            className="news-author" 
            key={author.id} 
            to={`/profile/${author.attributes.username}`}
            onClick={scrollToTop}
          >
            <div className="news-author-left">
              <img src={author.attributes.profilePicture.data.attributes.formats.thumbnail.url}/>
            </div>
            <div className="news-author-right">
              <h4 className="u-nomargin">{author.attributes.preferredName}</h4>
              <p className="u-nomargin">{author.attributes.wcrCommitteeRole}, {author.attributes.wcrRole} <br /> {author.attributes.subject}</p>
            </div>
          </Link>
        ))}
      </ContentBlock>
      <ContentBlock title="Description">
        <Markdown>{event.content}</Markdown>
      </ContentBlock>
      { event.banner.data && (
          <>
            { event.banner.data.attributes.caption &&
              (<ContentBlock title="Photo Acknowledgement">
                <p>{event.banner.data.attributes.caption}</p>
              </ContentBlock>) 
            }
          </> )
      }
    </> ) : (
    <>
      <TopBanner title="Freshers' > Updates" content="Not found!" />
      <ContentBlock title="Your requested page could not be found.">
        <section>
        Please navigate to your desired page using the navigation bar.
        </section>
      </ContentBlock>
    </> )
  );
};
  
export default FreshersUpdate;
