import React from "react";

import "./FilterMenu.css";

const FilterMenu = ({filter, updateFilter, colorKeys}) => {
  
  const tagsList = Object.keys(filter);

  return (
    <ul className="filtermenu-container">
      {
        tagsList.map((f) => {
          return (
            <li key={`${f}`} className="filtermenu-item">
              <input 
                type="checkbox" 
                id={`${f}`} 
                onChange={() => updateFilter(f)} 
                checked={filter[f]}
              />
              <label 
                htmlFor={`${f}`}
                style={{
                  backgroundColor: `rgb(${colorKeys[f].bkgColor})`,
                  color: `rgb(${colorKeys[f].textColor})`,
                }}
              >
                {f}
              </label>
            </li>
            )
          })
      }
    </ul>
  )
}

export default FilterMenu