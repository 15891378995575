import React from "react";
import { Link } from "react-router-dom";

import "../../utilities.css"
import "./AllSocieties.css";

import { tagToColor } from "../../data/colorKeys.js";
import default_img_src from "../../img/logo/univ_192x192.png";

const AllSocieties = ({ societiesArr, natureFltr, search }) => {
  const scrollToTop = () => {
    setTimeout(
      function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }, 50)
  };

  return (
    <div className="AllSocieties-container">
        {societiesArr.filter(
          // filter by nature
          (soc) => {
            // if no filter turns on then return all true
            if (!Object.values(natureFltr).some(item => item)) {
              return true
            } else {
              return natureFltr[soc.attributes.nature] //.data.some((oneNature) => natureFltr[oneNature.attributes.name])
            }
          }
        ).filter(
          (soc) => {
            // if no filter turns on then return all true
            if (!search) {
              return true
            } else {
              return soc.attributes.shortName.toLowerCase().includes(search.toLowerCase()) || soc.attributes.fullName.toLowerCase().includes(search.toLowerCase());
            }
          }
        ).sort(
          (soc1, soc2) => {return soc1.attributes.shortName > soc2.attributes.shortName}
        ).map(
          (soc) => { 
          
          let bG
          
          if (soc.attributes.banner.data) {
            bG = `url("${soc.attributes.banner.data.attributes.url}")`;
          } else {
            bG = `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${default_img_src}")`;
          }

          let finalKey = soc.attributes.nature || "Other"

          return (
            <div key={soc.id} className="AllSocieties-single">
              <Link to={`/societies/${soc.attributes.abbreviation}`} onClick={scrollToTop}>
              <div className="AllSocieties-background" style={{backgroundImage: bG}}> </div>
              <div className="AllSocieties-about"> 
                  <div className="AllSocieties-name"> 
                    <b>{soc.attributes.shortName} </b> 
                    <span className="AllSocieties-nature" style={{
                      backgroundColor: `rgb(${tagToColor[finalKey].bkgColor})`,
                      color: `rgb(${tagToColor[finalKey].textColor})`,
                    }}>{finalKey}</span>
                  </div>
              </div>
              </Link>
            </div>
          )
        })}
    </div>
  );
};
  
export default AllSocieties;