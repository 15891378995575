import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Markdown from "react-markdown";
// import { Fade } from "react-awesome-reveal";

import SocietyBanner from "../../modules/SocietyBanner.js";
import TopBanner from "../../modules/TopBanner.js";
import ContentBlock from "../../modules/ContentBlock.js";

import "../../../utilities.css"
import "./Society.css";


const Society = () => {
  let {abbreviation} = useParams();

  const [society, setSociety] = useState({});

  const getSociety = async () => {
    // const BaseURL = "http://localhost/strapi/api";
    const BaseURL = "https://wcr.univ.ox.ac.uk/strapi/api";

    const resSoc = await axios.get(`${BaseURL}/societies?filters[abbreviation][$eq]=${abbreviation}` + 
      "&populate[wcr_contacts][fields][0]=name&populate[wcr_contacts][fields][1]=website&populate[wcr_contacts][fields][2]=email" + 
      "&populate[president][fields][0]=name&populate[president][fields][1]=website&populate[president][fields][2]=email" +
      "&populate[banner][fields][0]=url&populate[banner][fields][1]=caption"
      ).then().catch((e) => console.log(e));
    setSociety(resSoc.data.data[0].attributes);
  };

  useEffect(() => {getSociety();}, []);

  return (
    society.fullName ? (
      <>
        <SocietyBanner societyObj={society} />

        <ContentBlock title="Description">
          <section className="society-intro">
            <Markdown>{society.description}</Markdown>
          </section>
        </ContentBlock>

        {
          society.regularSchedule && (<ContentBlock title="Meeting schedule for this term">
          <section className="society-intro">
            <Markdown>{society.regularSchedule}</Markdown>
          </section>
          </ContentBlock>)
        }
        
        {
          society.fullCommittee && (
            <ContentBlock title="Committee Details">
              <Markdown>{society.fullCommittee}</Markdown>
            </ContentBlock>
          )
        }

        { society.banner.data && (
          <>
            { society.banner.data.attributes.caption &&
              (<ContentBlock title="Photo Acknowledgement">
                <p>{society.banner.data.attributes.caption}</p>
              </ContentBlock>) 
            }
          </> )
        }
      </>
    ) : (
      <>
        <TopBanner title="Society" content="Society not found!" />
          <ContentBlock title="Your requested event could not be found.">
            <section>
            Please navigate to your desired page using the navigation bar.
            </section>
          </ContentBlock>
          </>
    )
  );
};
  
export default Society;
